import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { url: String, refreshInterval: Number }

    connected = false;
    headers = { "Accept": "text/vnd.turbo-stream.html" }

    connect() {
        if (this.connected) return;

        if (this.hasRefreshIntervalValue) {
            this.startRefreshing()
        }
        this.connected = true;
    }

    disconnect() {
        this.stopRefreshing()
    }

    load() {
        fetch(this.urlValue, { headers: this.headers, method: "GET"})
            .then(response => response.text())
            .then(html => Turbo.renderStreamMessage(html));
    }

    checkIfModalOpen() {
        const modal = document.getElementById("modal");
        if (modal?.innerHTML) {
            return true
        }
        return false
    }

    startRefreshing() {
        this.refreshTimer = setInterval(() => {
            if (this.checkIfModalOpen()) {
                return
            }
            this.load()
        }, this.refreshIntervalValue)
    }

    stopRefreshing() {
        if (this.refreshTimer) {
            clearInterval(this.refreshTimer)
        }
    }
}