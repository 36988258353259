import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["monthlyPlan", "yearlyPlan", "toggle"]

    connect() {
        this.togglePlan();
    }

    togglePlan() {
        const planToggle = this.toggleTarget.checked

        if (planToggle) {
            this.showYearlyPlans();
        } else {
            this.showMonthlyPlans();
        }
    }

    showMonthlyPlans() {
        this.monthlyPlanTargets.forEach(plan => plan.classList.remove("hidden"))
        this.yearlyPlanTargets.forEach(plan => plan.classList.add("hidden"))
    }

    showYearlyPlans() {
        this.monthlyPlanTargets.forEach(plan => plan.classList.add("hidden"))
        this.yearlyPlanTargets.forEach(plan => plan.classList.remove("hidden"))
    }
}
