/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)



// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

var turboRailsContext = require.context("@hotwired/turbo-rails", true);
turboRailsContext.keys().forEach(turboRailsContext);

import "flowbite/dist/flowbite.turbo.js";
import "trix";
import "@rails/actiontext"
import "../lib/youtube.js";
import { initFlowbite } from 'flowbite'

// Import contents of the controller folder
var controllerContext = require.context("../controllers", true);
controllerContext.keys().forEach(controllerContext);

import * as ActionCable from '@rails/actioncable'

ActionCable.logger.enabled = false;

import { Turbo } from "@hotwired/turbo-rails";

Turbo.StreamActions.redirect = function () {
    Turbo.visit(this.target);
};

document.addEventListener("turbo:before-stream-render", (event) => {
    const fallbackToDefaultActions = event.detail.render;
    event.detail.render = function (streamElement) {
        if (streamElement.action == "redirect") {
            Turbo.visit(streamElement.target);
        } else {
            fallbackToDefaultActions(streamElement);
        }
    };
});

// on turbo load
document.addEventListener("turbo:load", function () {
    initFlowbite();
});

import LocalTime from "local-time"
LocalTime.start()

Trix.config.blockAttributes.heading1.tagName = "h2"

ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount);
ReactRailsUJS.handleEvent('turbo:frame-load', ReactRailsUJS.handleMount, false);
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount);
